<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <div
          v-if="loaded"
          class="export-holder"
        >
          <vue-json-to-csv
            :json-data="usableDestinations"
          >
            <v-btn
              color="success"
              class="mr-6"
            >
              Export <i
                class="mdi mdi-export-variant"
                aria-hidden="true"
              />
            </v-btn>
          </vue-json-to-csv>
        </div>
        <v-card-title>
          Prize Destinations
        </v-card-title>
        <div
          v-if="!loaded"
          class="spinner"
        >
          <div class="pixel-spinner">
            <div class="pixel-spinner-inner" />
          </div>
        </div>
        <v-data-table
          :v-if="loaded"
          :headers="headers"
          :items="usableDestinations"
          class="elevation-1"
          multi-sort
        />
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import VueJsonToCsv from 'vue-json-to-csv'

  export default {
    name: 'PrizeDestination2',
    components: {
      VueJsonToCsv,
    },
    data () {
      return {
        loaded: false,
        headers: [
          { text: 'Registrant Name', value: 'registrantName' },
          { text: 'Car #', value: 'carNumber' },
          { text: 'Bank', value: 'bank' },
          { text: 'Account Name', value: 'accountName' },
          { text: 'Account Number', value: 'accountNumber' },
          { text: 'BSB', value: 'bsb' },
          { text: 'ABN', value: 'abn' },
          { text: 'GST', value: 'registered_for_gst' },

        ],
        usableDestinations: [],
      }
    },
    created () {
      this.getAllUsers('Registrant')
        .then(() => {
          return this.fetchUserOrderConfigDetails(this.allUsers.map((user) => user?._id))
        })
        .then(async (userConfigDetails) => {
          const destinations = await this.fetchPrizeDestinations(this.allUsers.map((user) => user?._id))
          return destinations.map((destination) => {
            const matchingUserConfig = userConfigDetails.find((detail) => detail?.id === destination?.person?._id)
            return {
              registrantName: matchingUserConfig?.firstName + ' ' + matchingUserConfig?.lastName,
              carNumber: matchingUserConfig?.carNumbers,
              bank: destination.bank_name,
              accountName: destination.account_name,
              accountNumber: destination.account_number,
              bsb: destination.bsb,
              abn: destination.abn,
              registered_for_gst: destination.registered_for_gst,
            }
          })
        })
        .then((mappedEntries) => {
          this.usableDestinations = mappedEntries
          this.loaded = true
        })
    },
    computed: {
      ...mapGetters(['allUsers']),
    },
    watch: {
    },
    mounted () {},
    methods: {
      ...mapActions(['fetchUserOrderConfigDetails', 'getAllUsers', 'fetchPrizeDestinations']),
    },
  }
</script>

<style scoped>
.spinner{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 100px 0px;
}

.export-holder{
  padding: 20px;
}

.pixel-spinner, .pixel-spinner * {
      box-sizing: border-box;
    }

    .pixel-spinner {
      height: 70px;
      width: 70px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .pixel-spinner .pixel-spinner-inner {
      width: calc(70px / 7);
      height: calc(70px / 7);
      background-color: #FAFAFA;
      color: #FAFAFA;
      box-shadow: 15px 15px  0 0,
                  -15px -15px  0 0,
                  15px -15px  0 0,
                  -15px 15px  0 0,
                  0 15px  0 0,
                  15px 0  0 0,
                  -15px 0  0 0,
                  0 -15px 0 0;
      animation: pixel-spinner-animation 2000ms linear infinite;
    }

    @keyframes pixel-spinner-animation {
      50% {
        box-shadow: 20px 20px 0px 0px,
                    -20px -20px 0px 0px,
                    20px -20px 0px 0px,
                    -20px 20px 0px 0px,
                    0px 10px 0px 0px,
                    10px 0px 0px 0px,
                    -10px 0px 0px 0px,
                    0px -10px 0px 0px;
      }
      75% {
        box-shadow: 20px 20px 0px 0px,
                    -20px -20px 0px 0px,
                    20px -20px 0px 0px,
                    -20px 20px 0px 0px,
                    0px 10px 0px 0px,
                    10px 0px 0px 0px,
                    -10px 0px 0px 0px,
                    0px -10px 0px 0px;
      }
      100% {
        transform: rotate(360deg);
      }
    }
</style>
